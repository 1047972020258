.nav {
    background-color: white;
    height: 6rem;
    padding: 0 2.5rem;
    position: sticky;
    z-index: 2;
    top: 0;

    .nav__row {
        max-width: 1200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        margin: 0 auto;

        .SLDlogo-wrapper {
            width: 16rem;

            a {
                display: flex;
                align-items: center;
            }

            .SLDlogo {
                width: 100%;
            }
        }

        .nav__items {
            display: flex;
            gap: 3rem;

            .nav__item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-decoration: none;
                list-style-type: none;
                color: var(--blueish-black);
                font-weight: 700;
                cursor: pointer;
                position: relative;
                height: 2.5rem;
            }

            .active::after {
                display: block;
                content: '';
                width: 100%;
                height: 1px;
                background-color: var(--dark-gold);
            }

            .dropdown--wrapper {
                display: grid;
                grid-template-rows: 0fr;
                opacity: 0;
                visibility: hidden;
                transition: grid-template-rows 250ms ease-out,
                    opacity 250ms ease-out,
                    visibility 250ms ease-out, ;
                position: absolute;
                background-color: white;
                z-index: 2;
                padding: 1rem .5rem;
                border: 1px solid black;
                min-width: 10rem;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);

                &::after {
                    content: '';
                    border-top: 1px solid black;
                    border-right: 1px solid black;
                    background-color: white;
                    width: .5rem;
                    height: .5rem;
                    position: absolute;
                    color: black;
                    top: -5px;
                    left: 50%;
                    transform: translateX(-50%) rotate(315deg);
                }

                .nav__sublist--wrapper {
                    overflow: hidden;
                    font-size: .8rem;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    width: fit-content;

                    .nav__subitem {
                        list-style: none;
                        position: relative;
                        padding: .25rem 0;
                        display: flex;
                        align-items: center;
                        font-weight: 400;

                        &::after {
                            position: absolute;
                            content: '';
                            width: 0%;
                            background-color: var(--tan);
                            height: 100%;
                            left: 0;
                            opacity: .4;
                        }

                        &:hover {
                            font-weight: 700;

                            // &::after {
                            //     width: 100%;
                            //     transition: width 250ms ease-in;
                            // }
                        }
                    }

                }
            }

            .dropdown--wrapper-open {
                grid-template-rows: 1fr;
                opacity: 1;
                visibility: unset;
            }
        }

        .hamburger--wrapper {
            display: none;
        }
    }

    .side__nav {
        display: none;
    }
}

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem 2.5rem;
    position: relative;
    z-index: 1;

    #homepage__landing {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 1200px;
        margin-top: 2rem;
        position: relative;
        min-height: 30rem;
        margin-bottom: 12rem;

        .homepage__landing--img--wrapper {
            width: 100%;
            max-height: 34rem;
            overflow-y: hidden;
            position: relative;
            -webkit-mask-image: linear-gradient(#004B8D 45%, rgba(0, 0, 0, 0.1));
            mask-image: linear-gradient(#004B8D 45%, rgba(0, 0, 0, .1));

            &::after {
                content: "";
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: 0;
                pointer-events: none;
                background-image: linear-gradient(to bottom, rgba(0, 75, 141, 0), rgba(0, 75, 141, 0.89) 90%);
                width: 100%;
                height: 40%;
            }

            .homepage__landing--img {
                width: 100%;
            }
        }

        .homepage__landing--desc-wrapper {
            position: absolute;
            z-index: 1;
            top: 80%;
            transform: translateY(-20%);

            h2 {
                margin-bottom: 1rem;
            }

            .homepage__landing--desc-top,
            .homepage__landing--desc-bottom {
                margin-bottom: 2rem;
               
            }
        }
    }

    .logos {
        display: flex;
        flex-wrap: wrap;
        padding: 0 10rem;
        max-width: 1200px;
        width: 100%;

        .logo--wrapper {
            width: calc(100% / 3);

            .logo {
                width: 100%;
            }
        }
    }

    .safety__logos {
        margin-top: 2rem;
    }

    .logos__text {
        margin-top: 1rem;
        font-size: 2rem;
        color: var(--tan);
    }

    #safety,
    #about {
        margin-top: 1rem;
        display: flex;
        width: 100%;
        max-width: 1400px;
        margin-top: 2rem;
        position: relative;

        .left-col {
            width: 60%;
            padding: 2rem 2rem;
            margin: auto 0;

            .desc {
                margin-top: 2rem;
                margin-bottom: 1rem;
            }

        }

        .right-col {
            max-width: 40%;
            flex-grow: 1;
            padding: 2rem 2rem;

            img {
                width: 100%;
            }
        }
    }
}

.titleWrapper {
    position: inherit;
    transition: all 0.2s ease-in-out !important;
    transform: scale(1) !important;
    box-shadow: none;
    animation-fill-mode: none !important;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    height: 100%;

    img {
        height: 100%;
        width: 100%;
        transition: all 0.2s ease-in-out !important;
        object-fit: cover;
    }

    &:hover {
        .projectTitle {
            color: white;
            padding-left: 2rem;
            padding-bottom: 2rem;
        }

        img {
            border-radius: 0.65rem !important;
            transform: scale(1.08) !important;
            box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03),
                0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03),
                0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05),
                0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);
        }

        .projectTitle::after {
            height: 250%;
        }
    }
}

.project--container {
    .header--wrapper {
        align-items: center;
        margin-bottom: 2rem;

        h2 {}

        p {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }
    }

    .navigation--text {

        img {
            margin-top: 2rem;
            transform: rotate(180deg);
        }
    }

    .galleryWrapper {
        .react-images__view {
            max-height: 50rem;
        }
    }
}

.react-images__view {

    img {
        max-height: 80vh;
    }
}

.projectTitle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 1.5rem 2rem;
    text-align: left;
    color: var(--light-gray);
    z-index: 1;
    transition: all 0.3s ease 0s;
}

.projectTitle::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.8));
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: var(--light-gray);
    z-index: -1;
    transition: all 0.3s ease 0s;
}


.react-photo-gallery--gallery img {
    transition: all 0.3s ease-in-out;
}

.react-photo-gallery--gallery img:hover {
    transform: scale(1.05);
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.33);
}

label.MuiFormLabel-root {
    font-size: 1.25rem !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #253053 !important;
}

.MuiInputBase-input {
    color: #33ff33;
}

*:focus {
    outline: none !important;
}

.contactFields fieldset legend span {
    font-size: 1.01rem;
    padding-right: 2px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.grab-pointer {
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}

.cursor-think {
    cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='30' height='36' viewport='0 0 100 100' style='fill:black;font-size:18px;'><text y='50%'>🤔</text></svg>") 16 0, auto !important;
}

.heightProcess {
    height: 36rem;
}

.verticalShift {
    height: 148%;
}



.processPosition {
    z-index: 1000;
    position: relative;
    height: calc(100% - 5rem);
    justify-content: flex-end;
    align-items: center;

    // @include media-breakpoint-down(md) {
    //     width: 100%;
    //     justify-content: center;
    //     align-items: flex-end;
    // }
}

.project__header {
    margin-bottom: 2rem;
}

.fullscreen {
    .react-images__navigation {
        background-color: unset;
    }
}

.alignContent {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    // @include media-breakpoint-down(md) {
    //     flex-direction: column;

    //     &.creative {
    //         width: 100%;
    //         justify-content: center;
    //         align-items: flex-end;
    //     }
    // }
}

@media (max-width: 1280px) {
    .MuiGrid-container {
        .MuiGrid-grid-md-11 {
            width: 100%;
            flex-grow: unset;
            max-width: unset;
            flex-basis: unset;

            .titleWrapper {
                height: 20rem;
                width: 100%;

                img {
                    object-fit: cover;
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .MuiGrid-container {
        .MuiGrid-grid-md-11 {
            width: 100%;
            flex-grow: unset;
            max-width: unset;
            flex-basis: unset;

            .titleWrapper {
                height: 20rem;
                width: 100%;

                img {
                    object-fit: cover;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .project--container {
        .MuiGrid-container {
            .MuiGrid-item {
                padding-bottom: 16px;
            }

            .MuiGrid-grid-md-11 {
                .titleWrapper {
                    height: 15rem;
                }
            }
        }
    }
}
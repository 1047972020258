.safety__overview_container {
    .safety__overview--list {
        margin: 2rem 0 1rem 2rem;
        color: var(--light-gray);
    }

    .safety__overview--wrapper {
        display: flex;
        justify-content: space-between;
    }

    #testimonial {
        .slick-list {
            height: 10rem;

            //

        }

        .svg-inline--fa {
            overflow: hidden;
        }
    }
}

.safety__row {
    .accordian--wrapper {
        border-bottom: 1px solid var(--gold);

        .accordian {
            cursor: pointer;
            padding: .5rem 1rem .5rem 1rem;
            background-color: var(--gold);
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: white;
        }

        .accordian__icon {
            width: 1rem;
        }

        .accordian__content--wrapper {
            display: grid;
            grid-template-rows: 0fr;
            transition: grid-template-rows 250ms ease-out;

            .accordian__content {
                overflow: hidden;

                .safety__info--row {
                    display: flex;
                    justify-content: space-around;
                    overflow: hidden;
                    margin-bottom: 1rem;

                    .safety__info--wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;

                        .safety__details--header {
                            margin-top: 1rem;
                        }

                        .safety__info--col {
                            ul {
                                margin-top: 1rem;
                                list-style: none;
                                color: var(--light-gray);
                                line-height: 20px;
                                margin-left: 1rem;
                                min-height: 13rem;

                                li::before {
                                    content: "\2022";
                                    color: white;
                                    font-weight: bold;
                                    display: inline-block;
                                    width: 1rem;
                                }

                                .sublist {
                                    margin-left: 1rem;
                                    margin-top: unset;
                                    min-height: unset;
                                }
                            }

                        }
                    }

                }

                .safety__info--row-one-col {
                    justify-content: unset;

                    .safety__info--wrapper {
                        gap: unset;

                        .safety__info--col {
                            ul {
                                min-height: unset;
                            }
                        }
                    }
                }

                .safety__info-desc {
                    overflow: hidden;
                    color: var(--light-gray);
                    margin-bottom: 1rem;
                }
            }
        }

        .accordian__content--wrapper-open {
            grid-template-rows: 1fr;
        }
    }
}

.images {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .image-wrapper {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

    }
}

.header--wrapper {
    max-width: 1200px;

    .safety__details-para {
        margin-top: 2rem;
    }
}



.header--wrapper-details {
    .safety__details--para-wrapper {
        display: flex;
        gap: 1rem;

        figure {
            width: 55rem;

            img {
                width: 100%;
            }
        }
    }
}

// @media (max-width: 480px) {
//     .safety__overview_container {
//         .safety__overview--wrapper {
//             flex-direction: column;
//             justify-content: unset;
//             gap: 1rem;

//             figure {
//                 width: 100%;
//                 height: 8rem;

//                 img {
//                     height: 100%;
//                     object-fit: cover;
//                     width: 100%;
//                 }
//             }
//         }
//     }
// }
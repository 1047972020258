footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: white;
    background-color: var(--dark-gold);
    height: 3rem;

    .info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;
    }
}



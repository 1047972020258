@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Heebo', 'Roboto', sans-serif;
}

body {
    margin: 0 !important;
    padding: 0 !important;
    background-color: var(--blue);
    background: linear-gradient(90deg,
            rgba(0, 0, 0, .80) 0%,
            rgba(12, 49, 82, 0.918) 10%,
            rgba(0, 75, 141, 1) 50%,
            rgba(12, 49, 82, 0.918) 90%,
            rgba(0, 0, 0, .80) 100%, );
    font-size: 1rem;
    overflow-x: hidden;

}


:root {
    --blue: #004B8D;
    --dark-gold: #7C6D47;
    --gold: #A8996E;
    --tan: #E2CEA6;
    --dark-grey: #4D4D4D;
    --blueish-black: #002546;
    --light-gold: #FAE5BB;
    --light-gray: #D5D5D5;
}

.blue-text {
    color: var(--blue);
}

.center {
    text-align: center;
}

.App {
    position: relative;
    padding-bottom: 3rem;
    height: 100%;
    min-height: 100vh;
}

a {
    color: inherit;
    text-decoration: inherit;
}

h1 {
    font-size: 1.5rem;
    color: var(--tan);
    font-weight: 500;
}

p {
    color: var(--light-gray);
    line-height: 1.5rem;
}

span {
    color: var(--light-gold);
    cursor: pointer;
    width: fit-content;
}

h2 {
    color: white;
    font-weight: 700;
    font-size: 1.5rem;
}

h3 {
    color: white;
    font-weight: 700;
    font-size: 1.3rem;
}

h4 {
    color: white;
    font-weight: 700;
    font-size: 1.1rem;
}


.team-link {
    text-decoration: underline;
}


html {
    scroll-behavior: smooth;
    scroll-padding-top: 6rem;
    font-size: 16px;
}

.container {
    .header--wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .header--big {
            font-size: 2.5rem;
            color: white;
            text-align: center;
            margin-bottom: 1rem;
            font-weight: 700;
        }

        h1 {
            text-align: center;
            max-width: 45rem;
        }
    }

    .row {
        margin-top: 3rem;
        width: 100%;
        max-width: 1200px;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .left--col {
            max-width: 60%;
            padding-right: 3rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .desc {
                margin-top: 2rem;
                margin-bottom: 1rem;
            }
        }

        .right--col {
            width: 100%;
            max-width: 40%;
            padding: 2rem;

            .image {
                width: 100%;
            }
        }

    }
}

.navigation--underline {
    text-decoration: underline;
}


#testimonial {
    display: flex;
    width: 100%;
    max-width: 1400px;
    position: relative;

    .slick-slide {
        margin: 1rem;
        height: fit-content;
    }

}

.slick-list {
    height: 20rem;

    .slick-track {
        height: inherit;
        display: flex;
        align-items: center;
    }
}

.testimonials {
    max-width: 1400px;
    width: 100%;
}

@media(max-height: 700px) {
    body {
        .nav {
            .side__nav {
                padding: 1rem;

                .side__nav--wrapper {
                    gap: .25rem;

                    .side__nav__subheader {
                        margin-top: .5rem;
                    }
                }

                .side__nav__footer {
                    gap: .5rem;
                }

                .side__nav__header--wrapper {
                    .side__nav__header {
                        height: 60px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    body {
        background: linear-gradient(90deg,
                rgba(0, 0, 0, .80) -2%,
                rgba(12, 49, 82, 0.918) 10%,
                rgba(0, 75, 141, 1) 50%,
                rgba(12, 49, 82, 0.918) 90%,
                rgba(0, 0, 0, .80) 102%, );

    }


    .slick-next {
        right: -12px;
    }

    .slick-prev {
        left: -12px;
    }

    .side__nav--open {
        // overflow: hidden;
    }

    .background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: black;
        opacity: .3;
        z-index: 3;
        overflow: hidden;
    }

    .nav {
        padding: 0 1rem;

        .nav__row--mobile {
            .nav__items {
                display: none;
            }

            .SLDlogo-wrapper {
                width: 14rem;
            }

            .hamburger--wrapper {
                display: unset;
                width: 2.5rem;
                height: 2.5rem;
                overflow: hidden;

                .hamburger {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: black;
            opacity: .3;
            z-index: 3;
            overflow: hidden;
        }

        .side__nav {
            background-color: white;
            position: fixed;
            z-index: 4;
            top: 0;
            right: 0;
            height: 100vh;
            padding: 2.5rem 1rem;
            width: 14.5rem;
            display: flex;
            flex-direction: column;
            transition: right 250ms ease-in;
            overflow: hidden;

            .side__nav--wrapper {
                text-decoration: none;
                list-style: none;
                display: flex;
                flex-direction: column;
                gap: .5rem;

                .side__nav__subheader {
                    margin-top: 1.5rem;
                    font-weight: bold;
                    font-size: 1.1rem;
                }

                .side__nav__item {
                    margin-left: .5rem;
                }
            }

            .side__nav__header--wrapper {
                display: flex;
                justify-content: center;
                position: relative;
                align-items: center;

                figure {
                    position: absolute;
                    left: 0;
                    fill: black;
                    width: 1.25rem;
                    height: 1.25rem;
                    transform: rotate(180deg);

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .side__nav__header {
                    font-weight: bold;
                    font-size: 1.2rem;
                    height: 60px;

                    svg {
                        #svg__corners {
                            animation: corners 250ms ease-out 250ms;
                            transform-origin: center;
                        }

                        @keyframes corners {
                            from {
                                transform: rotateZ(0deg);
                            }

                            to {
                                transform: rotateZ(-180deg);
                            }
                        }
                    }
                }
            }

            .side__nav__footer {
                margin-top: auto;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .info {
                    display: flex;
                    gap: .5rem;
                    font-size: .8rem;
                    text-decoration: underline;

                    img {
                        color: black;
                    }
                }
            }
        }

        .close {
            right: -14.5rem;
            opacity: 0;
            width: 0%;
            padding: 0 0;
            visibility: hidden;
            transition: opacity 0s ease-in 250ms, visibility 0s ease-in 250ms, width 250ms ease-in, right 250ms ease-in, padding 0s ease-in 250ms;

            figure {
                display: none;
            }

            .side__nav--wrapper {
                opacity: 0;
            }
        }
    }

    .container {
        padding: 2rem 2rem;

        .logos {
            padding: 0 1rem 0 1rem;
            margin-top: 4rem;
        }

        .row {
            .service--wrapper {
                .left--col {
                    max-width: 50%;
                    padding: unset;
                }

                .right--col {
                    display: flex;
                    align-items: center;
                    max-width: 50%;
                }
            }

            .about--wrapper {
                .left--col {
                    padding-right: 2rem;
                }

                .right--col {
                    padding: unset;
                    display: flex;
                    align-items: center;
                }
            }

        }

        //safety//
        #safety {
            .right-col {
                display: flex;
                align-items: center;
            }
        }

        //ABOUT//
        #about {
            .right-col {
                display: flex;
                align-items: center;
            }
        }

        .safety__overview--wrapper {
            .left--col {
                max-width: 50%;
                padding-right: 2rem;
            }

            .right--col {
                max-width: 50%;
                display: flex;
                align-items: center;
                padding: unset;
            }
        }
    }
}

@media (max-width: 480px) {
    p {
        font-size: .9rem;
    }

    h2 {
        font-size: 1.3rem;
    }


    .container {
        padding: 2rem 1.25rem;

        //HOMEPAGE//
        #homepage__landing {
            min-height: unset;
            margin-bottom: unset;

            .homepage__landing--desc-wrapper {
                position: unset;

                .homepage__landing--desc-top,
                .homepage__landing--desc-bottom {
                    margin-bottom: 1.5rem;
                }
            }
        }

        .logos {
            margin-top: -4rem;
            padding: unset;
            margin-bottom: 2rem;
        }

        .logos__text {
            font-size: 1.5rem;
            margin-top: unset;
        }

        //SAFETY//
        #safety {
            margin-top: 1.5rem;
            display: unset;

            .left-col {
                width: unset;
                padding: unset;

                .desc {
                    margin-top: 1rem;
                }
            }

            .right-col {
                max-width: unset;
                padding: 1rem 0;
                height: 12rem;

                img {
                    object-fit: cover;
                    height: 100%;
                }
            }
        }

        .safety__logos {
            margin-top: unset;
            margin-bottom: .5rem;
        }



        //ABOUT//
        #about {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;

            .left-col {
                width: unset;
                padding: unset;
                margin: unset;

                .desc {
                    margin-top: 1rem;
                }
            }

            .right-col {
                order: 2;
                padding: 1rem 0;
                height: 12rem;
                max-width: unset;

                img {
                    object-fit: cover;
                    height: 100%;
                }
            }
        }

        .contact--text {
            display: none;
            // font-size: 1.2rem;
        }

        //SAFETY//
        .header--wrapper {
            .header--big {
                font-size: 2rem;
                margin-bottom: unset;

                .images {
                    margin-top: unset;
                }
            }
        }

        .row {
            margin-top: 1.8rem;
        }

        //CERTIFICATION DETAILS
        .safety__row {
            margin-top: unset;

            .accordian--wrapper {
                margin-bottom: 1rem;

                .accordian__content--wrapper {
                    .accordian__content {
                        .safety__info--row {
                            display: unset;

                            .safety__info--wrapper {
                                gap: 1rem;
                                margin-top: 1rem;

                                .safety__details--header {
                                    margin-top: unset;
                                    margin-bottom: .5rem;
                                }

                                .safety__info--col {
                                    h4 {
                                        font-size: 1rem;
                                    }

                                    ul {
                                        margin-top: .5rem;
                                        min-height: unset;
                                        margin-bottom: 1rem;

                                        .sublist {
                                            margin-top: unset;
                                            margin-bottom: unset;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .safety__info-desc {
                    margin-top: unset;
                }
            }

            .navigation--wrapper {
                margin-bottom: unset;
                margin-top: unset;
            }
        }

        //SERVICES
        .header--wrapper-mobile {
            margin-bottom: .5rem;

            .header--big {
                margin-bottom: 2rem;
                text-align: unset;
            }

            h1 {
                text-align: unset;
                font-size: 1.3rem;
            }
        }

        .row {
            .row__header {
                text-transform: uppercase;
            }

            .service--wrapper {
                flex-direction: column;
                justify-content: unset;
                gap: 1rem;

                figure {
                    width: 100%;
                    height: 8rem;

                    img {
                        height: 100%;
                        object-fit: cover;
                        width: 100%;
                    }
                }


                .teamImageWrapper {
                    max-width: 100%;
                }

                .teamImage {
                    object-fit: contain;
                }
            }
        }

        //ABOUT//
        .about--wrapper {
            .left--col {
                width: unset;
            }
        }
    }

    .safety__overview_container {
        .safety__overview--wrapper {
            flex-direction: column;
            justify-content: unset;
            gap: 1rem;

            figure {
                width: 100%;
                height: 8rem;

                img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }

            .OSHAlogo {
                height: 8rem;

                img {
                    object-fit: contain;
                }
            }

            .safety__overview--list {
                margin-top: unset;
                margin-bottom: unset;
            }
        }
    }

    footer {
        .info {
            display: none !important;
        }
    }
}
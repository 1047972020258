.service--wrapper {
    display: flex;
    justify-content: space-between;

    .left--col {
        max-width: 60%;
        padding-right: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .desc {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }
    }

    .right--col {
        width: 100%;
        max-width: 40%;
        padding: 2rem;

        .service__image {
            width: 100%;
        }
    }

    .list--wrapper {
        margin: 2rem 0 1rem 2rem;
        color: var(--light-gray);
    }
}

.contact--text-margin {
    margin-top: 3rem;
}

.navigation--wrapper-service {
    margin-top: 1rem;
    width: 100%;
    max-width: 1200px;
}

.navigation--text-back {
    img {
        transform: rotate(180deg);
    }
}
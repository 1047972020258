.about--wrapper {
    display: flex;
    justify-content: space-between;

    .left--col {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 60%;
        justify-content: unset !important;

        .info {
            display: flex;
            gap: 1rem;
            color: white;
        }

        p {
            color: white;
        }
    }

    .right--col {
        flex-grow: 1;
        max-width: 20rem;
        margin-top: -5rem;

        img {
            width: 100%;
        }
    }
}